<template>
    <div class="default-bg pt-5 pb-5">
        <div class="row pb-3">
            <div class="col-lg-12">
                <skeleton-box height="40px" width="300px" />
            </div>
        </div>
        <div class="row pb-3">
            <div class="col-1">
                <skeleton-box height="30px" width="100%" />
            </div>
            <div class="col-3">
                <skeleton-box height="30px" width="100%" />
            </div>
            <div class="col-2">
                <skeleton-box height="30px" width="100%" />
            </div>
            <div class="col-1">
                <skeleton-box height="30px" width="100%" />
            </div>
            <div class="col-2">
                <skeleton-box height="30px" width="100%" />
            </div>
            <div class="col-3">
                <skeleton-box height="30px" width="100%" />
            </div>
        </div>
        <div class="row pb-4">
            <span class="col-lg-2 col-sm-3 col-5">
                <skeleton-box height="200px" width="100%" />
                <p></p>
                <skeleton-box height="30px" width="60%" />
                <p></p>
                <skeleton-box height="15px" width="30px" />
                <p></p>
                <skeleton-box height="15px" width="50px" />
                <p></p>
                <skeleton-box height="40px" width="40px" />
            </span>
            <span class="col-lg-3 col-sm-4 col-5">
                <skeleton-box height="200px" width="100%" />
                <p></p>
                <skeleton-box height="30px" width="60%" />
                <p></p>
                <skeleton-box height="15px" width="30px" />
                <p></p>
                <skeleton-box height="15px" width="50px" />
                <p></p>
                <skeleton-box height="40px" width="40px" />
            </span>
            <span class="col-lg-3 col-sm-4 d-sm-block d-none">
                <skeleton-box height="200px" width="100%" />
                <p></p>
                <skeleton-box height="30px" width="60%" />
                <p></p>
                <skeleton-box height="15px" width="30px" />
                <p></p>
                <skeleton-box height="15px" width="50px" />
                <p></p>
                <skeleton-box height="40px" width="40px" />
            </span>
            <span class="col-lg-3 d-lg-block d-none">
                <skeleton-box height="200px" width="100%" />
                <p></p>
                <skeleton-box height="30px" width="60%" />
                <p></p>
                <skeleton-box height="15px" width="30px" />
                <p></p>
                <skeleton-box height="15px" width="50px" />
                <p></p>
                <skeleton-box height="40px" width="40px" />
            </span>
            <span class="col-lg-1 col-sm-1 col-2">
                <skeleton-box height="200px" width="100%" />
                <p></p>
                <skeleton-box height="30px" width="100%" />
                <p></p>
                <skeleton-box height="15px" width="30px" />
                <p></p>
                <skeleton-box height="15px" width="50px" />
                <p></p>
                <skeleton-box height="40px" width="40px" />
            </span>
        </div>
        <div class="row">
            <div class="col-lg-12">
                <skeleton-box height="5px" width="100%" />
            </div>
        </div>
    </div>
</template>

<script>
import SkeletonBox from '@/components/blocks/SkeletonBox.vue';

export default {
    components: {
        SkeletonBox
    },
}
</script>