<template>
    <div class="default-bg">
        <section
            class="section breadcrumb-area pb-90 page-header"
            style="background: url('/storage/app/media/produse.jpg') center no-repeat; padding-bottom:105px;"
        >
        </section>
        <section class="container p-3 rounded pb-4">
            <div class="row">
                <div class="col-lg-6">
                    <skeleton-box :min-width="20" :max-width="50"/>
                    <skeleton-box />
                    <skeleton-box />
                    <skeleton-box />
                    <p></p>
                    <skeleton-box height="500px" width="100%" />
                </div>
                <div class="col-lg-6">
                    <div class="pt-1 pb-1">
                        <skeleton-box height="30px"  width="215px" />
                    </div>
                    <div class="pt-1 pb-1">
                        <skeleton-box height="20px" width="300px" />
                    </div>
                    <div class="row pt-5 pb-5">
                        <div class="col-lg-4 col-md-4 col-sm-4">
                            <skeleton-box height="40px" width="120px" />
                        </div>
                        <div class="col-lg-8 col-md-8 col-sm-8">
                            <skeleton-box height="40px" width="100%" />
                        </div>
                    </div>
                    <div class="pt-1 pb-2">
                        <skeleton-box :max-width="55"/>
                        <skeleton-box :max-width="55"/>
                        <skeleton-box :max-width="55"/>
                    </div>
                    <div class="pt-1 pb-1">
                        <div v-for="n in 12" :key="n">
                            <skeleton-box :min-width="20" :max-width="50"/>
                        </div>
                    </div>
                </div>
                <div class="col-12">
                    <p></p>
                </div>
                <div class="col-lg-6">
                    <skeleton-box :min-width="20" :max-width="80"/>
                    <p></p>
                    <skeleton-box v-for="n in 20" :key="n" />
                </div>
                <div class="col-lg-6">
                    <skeleton-box :min-width="20" :max-width="80"/>
                    <p></p>
                    <div v-for="n in 5" :key="n" class="pt-1 pb-1">
                       <skeleton-box  height="50px" width="100%" />
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
import SkeletonBox from '@/components/blocks/SkeletonBox.vue';

export default {
    components: {
        SkeletonBox
    },
}
</script>